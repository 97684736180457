import { FlexColumn, FlexRow } from "components/common/containers";
import { useState } from "react";

//StyledComponents
import { StyledButton, StyledPopup } from "styledComponents";

//Service
import { rechargeApi, subscriptionApi } from "services/api";

//Components
import TextInput from "../../../../../../components/ui/inputs/FormInput";
import { ISubscription } from "interfaces/models";
import { accountsApi } from "services/api/accounts-api";

export const SubscriptionActions = ({
  status,
  id,
  refetch,
  subscription,
  additionalInfo,
}: {
  status?: string;
  subscription: ISubscription;
  id: string;
  refetch: () => void;
  additionalInfo?: Record<string, string>;
}) => {
  const [cancelReason, setReason] = useState("");
  const [popupOpen, setPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <FlexColumn>
      <StyledButton
        sx={{ width: "fit-content", marginBottom: "0.5rem" }}
        loading={isLoading}
        addBorder
        onClick={() => {
          setIsLoading(true);
          accountsApi
            .setTrainingReminder(
              subscription.subscription_email,
              subscription.additional_info
                ? subscription.additional_info["subscriber_first_name"]
                : ""
            )
            .then(() => setIsLoading(false));
        }}
      >
        Training Completed
      </StyledButton>
      {status && (
        <>
          {status === "ACTIVE" && (
            <>
              <StyledButton
                sx={{ width: "fit-content", marginBottom: "0.5rem" }}
                addBorder
                onClick={() => setPopupOpen(true)}
              >
                Cancel Subscription
              </StyledButton>
              <StyledPopup
                title={`Cancel subscription ${id}`}
                open={popupOpen}
                maxWidth="xs"
                onClose={() => setPopupOpen(false)}
              >
                <FlexRow>
                  <TextInput
                    placeholder="Cancellation Reason"
                    value={cancelReason}
                    onChange={({ target: { value } }) => setReason(value)}
                  />
                  <StyledButton
                    sx={{ marginTop: "-0.5rem" }}
                    onClick={() =>
                      rechargeApi
                        .cancelSubscription(id, cancelReason)
                        .then(() => {
                          setPopupOpen(false);
                          refetch();
                        })
                    }
                  >
                    Cancel Subscription
                  </StyledButton>
                </FlexRow>
              </StyledPopup>
            </>
          )}
          {status === "CANCELLED" && (
            <StyledButton
              sx={{ width: "fit-content", marginBottom: "0.5rem" }}
              addBorder
              onClick={() => rechargeApi.activateSubscription(id).then(refetch)}
            >
              Activate Subscription
            </StyledButton>
          )}
        </>
      )}
      {additionalInfo && (
        <StyledButton
          addBorder
          loading={isLoading}
          sx={{
            width: "fit-content",
            marginBottom: "0.5rem",
            ...(status && { marginLeft: "0.5rem" }),
          }}
          onClick={() => {
            setIsLoading(true);
            subscriptionApi
              .resendActivationCode({
                customer_email: subscription.subscription_email,
                activation_code: subscription.activation_code,
                customer_name: `${additionalInfo["subscriber_first_name"]} ${additionalInfo["subscriber_last_name"]}`,
                language:
                  subscription.product_id?.toString() === "7395228745810"
                    ? "sp"
                    : "en",
                product_name: ["7395228745810", "7167828820050"].includes(
                  subscription.product_id
                )
                  ? "CalmiGo Plus UHG"
                  : additionalInfo["variant_title"],
              })
              .then(() => setIsLoading(false));
          }}
        >
          Resend Activation Code
        </StyledButton>
      )}
    </FlexColumn>
  );
};
