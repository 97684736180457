import { useState } from "react";

//Constants
import {
  SEARCH,
  DEFAULT_VARIANTS,
  CREATE_SUBSCRIPTION_FORM_NON_EXISTING,
  CREATE_SUBSCRIPTION_FORM_EXISTING_CUSTOMER,
  ALERT_MESSAGE,
} from "./constants";

//Components
import { LookFor } from "../lookFor";
import { StyledAlert } from "styledComponents";
import { Form, InputPicker, CheckBoxGroup } from "components";

//Style
import {
  Root,
  Section,
  Container,
  CreateButton,
} from "./CreateSubscription.style";
import { palette } from "theme/theme";

//Interface
import { SubscriptionData } from "interfaces/models";

//Utils
import { getDetails } from "utils";

//Services
import { rechargeApi } from "services/api";
import { Switch } from "@mui/material";
import { validateEmailAndPhone } from "./utils";
import usePermission from "hooks/usePermission";

export const CreateSubscription = () => {
  const adminAndStronger = usePermission("admin:>=");

  const [data, setData] = useState<SubscriptionData>({} as SubscriptionData);
  const [searchBy, setSearchBy] = useState({ param: "", value: "" });
  const [selectedOrder, setSelectedOrder] = useState("");
  const [addToRecharge, setAddToRecharge] = useState(false);
  const [isExist, setIsExist] = useState(false);
  const [openAlert, setOpenAlert] = useState<
    "error" | "success" | "phone" | "email" | undefined
  >();

  const handleChange = (key: string, value: string) => {
    setData((prev) => ({ ...prev, [key]: value }));
  };

  const details = getDetails<SubscriptionData>(
    isExist
      ? CREATE_SUBSCRIPTION_FORM_EXISTING_CUSTOMER
      : CREATE_SUBSCRIPTION_FORM_NON_EXISTING,
    data
  );

  const getSubscriptionsOptions = (
    subscription: SubscriptionData
  ): {
    label: string;
    value: string | undefined;
  }[] => {
    const filteredOrder = subscription.orders
      ?.map(({ lineItems }) =>
        lineItems.filter(
          ({ name }) =>
            name.includes("Subscription") || name.includes("Lifetime")
        )
      )
      .flat();

    return (
      (filteredOrder?.length
        ? subscription.orders
            ?.map(({ lineItems, name: orderName }) =>
              lineItems
                .filter(
                  ({ name }) =>
                    name.includes("Subscription") || name.includes("Lifetime")
                )
                .map(({ name, id }) => ({
                  label: `${orderName} - ${name}`,
                  value: id.split("/").pop(),
                }))
            )
            .flat()
        : DEFAULT_VARIANTS) || DEFAULT_VARIANTS
    );
  };

  return (
    <Root>
      <Container>
        <Section>
          <InputPicker
            options={SEARCH}
            label="Search"
            value={searchBy.value}
            onChange={(param: string, value: string) =>
              setSearchBy({ param, value })
            }
            reset={() => {
              setSearchBy({ param: "", value: "" });
              setIsExist(false);
            }}
          />
          <LookFor
            {...searchBy}
            onDataReturn={(data: any) => {
              setData(data);
              setIsExist(!!Object.values(data).length);
              setSelectedOrder("");
            }}
          />
        </Section>
        <Section>
          <Form
            details={details}
            row={data}
            onChange={isExist ? undefined : handleChange}
          />
        </Section>
      </Container>
      <Container>
        <CheckBoxGroup
          selectedValue={selectedOrder}
          onChange={(value, label) => {
            setSelectedOrder(value);
            setData((prev) => ({
              ...prev,
              title: label.replace(/^#\d+\s*-\s*/, "").trim(),
            }));
          }}
          options={getSubscriptionsOptions(data)}
        />
        {adminAndStronger && (
          <>
            add to recharge?
            <Switch
              value={addToRecharge}
              onChange={({ target }) => setAddToRecharge(target.checked)}
            />
          </>
        )}
        {selectedOrder !== "" && (
          <CreateButton
            onClick={() => {
              const validate = isExist
                ? false
                : validateEmailAndPhone(data.email, data.phone);
              if (validate) {
                setOpenAlert(validate);
              } else {
                rechargeApi
                  .createSubscription({
                    ...data,
                    createOnRecharge: addToRecharge,
                    variantId: selectedOrder,
                  })
                  .then((data) => {
                    setOpenAlert(!!data ? "success" : "error");
                    setData({} as SubscriptionData);
                    setSelectedOrder("");
                  });
              }
            }}
          >
            create
          </CreateButton>
        )}
      </Container>
      <StyledAlert
        open={!!openAlert}
        color={
          openAlert === "success"
            ? palette.success.light
            : openAlert && palette.error.light
        }
        onClose={() => setOpenAlert(undefined)}
        message={(openAlert && ALERT_MESSAGE[openAlert]) || ""}
      />
    </Root>
  );
};
