export const validateEmailAndPhone = (
  email: string,
  phoneNumber: string
): "email" | "phone" | false => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phonePattern = /^\+\d{10,15}$/;

  if (!emailPattern.test(email)) {
    return "email";
  }
  console.log({ phoneNumber });

  if (!phonePattern.test(phoneNumber)) {
    console.log("no");
    return "phone";
  }

  return false;
};
