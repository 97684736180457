//Forms
import {
  UploadBoxFormRules,
  UpdateBoxFormRules,
  UpdateLevelFormRules,
  UpdateSceneFormRules,
  UploadLevelFormRules,
  UploadSceneFormRules,
  UploadFirmwareFormRules,
  UpdateBoxPrizeFormRules,
  UploadBoxPrizeFormRules,
  UpdateSceneItemFormRules,
  UploadSceneItemFormRules,
  UpdateGameConfigFormRules,
  UpdateDailyRewardFormRules,
  UploadDailyRewardFormRules,
  UpdateLibraryImageFormRules,
  UploadLibraryImageFormRules,
} from "../../components/navigation/FormsDefinitions";

//Components
import Fota from "../../components/fota/Fota";
import Boxes from "../../components/boxes/Boxes";
import Levels from "../../components/levels/Levels";
import BoxPage from "../../components/boxes/BoxPage";
import LevelPage from "../../components/levels/LevelPage";
import Library from "../../components/img-library/Library";
import PrizePage from "../../components/boxes/prizes/PrizePage";
import GameConfig from "../../components/game-config/GameConfig";
import { Accounts, AccountPage } from "../../components/accounts";
import DailyRewards from "../../components/daily-rewards/DailyRewards";
import DailyRewardPage from "../../components/daily-rewards/DailyRewardPage";
import LibraryImagePage from "../../components/img-library/LibraryImagePage";
import { Subscription, SubscriptionPage } from "../../pages/subscription";

//Common
import { ROUTES_PATHS } from "../../common";

//Interfaces
import { Route } from "../../routes/interfaces";

//Pages
import {
  Orders,
  Scenes,
  Reports,
  ScenePage,
  CmsAccounts,
  SceneItemPage,
  CmsAccountPage,
} from "pages";

export const ROUTES: Route[] = [
  {
    path: ROUTES_PATHS.Dashboard.accounts,
    mainComponent: Accounts,
    adminOnly: false,
    permissionKey: "Accounts",
  },
  {
    path: ROUTES_PATHS.Account.accountPage,
    mainComponent: AccountPage,
    adminOnly: false,
    permissionKey: "Accounts",
  },
  {
    path: ROUTES_PATHS.Dashboard.manageAccount,
    mainComponent: CmsAccounts,
    adminOnly: true,
    permissionKey: "Manage Accounts",
  },
  {
    path: ROUTES_PATHS.CmsAccounts.CmsAccountPage,
    mainComponent: CmsAccountPage,
    adminOnly: false,
    permissionKey: "Manage Accounts",
  },
  {
    path: ROUTES_PATHS.Dashboard.subscription,
    mainComponent: Subscription,
    adminOnly: false,
    permissionKey: "Subscriptions",
  },
  {
    path: ROUTES_PATHS.Subscription.subscriptionPage,
    mainComponent: SubscriptionPage,
    adminOnly: false,
    permissionKey: "Subscriptions",
  },
  {
    path: ROUTES_PATHS.Dashboard.orders,
    mainComponent: Orders,
    adminOnly: false,
    permissionKey: "B2B Orders",
  },
  {
    path: ROUTES_PATHS.Dashboard.reports,
    mainComponent: Reports,
    adminOnly: false,
    permissionKey: "Reports",
  },
  {
    path: ROUTES_PATHS.Dashboard.library,
    mainComponent: Library,
    adminOnly: false,
    permissionKey: "Library",
  },
  {
    path: ROUTES_PATHS.Library.imagePage,
    mainComponent: LibraryImagePage,
    metadata: {
      editForm: UpdateLibraryImageFormRules,
      createForm: UploadLibraryImageFormRules,
    },
    adminOnly: false,
    permissionKey: "Library",
  },
  {
    path: ROUTES_PATHS.Dashboard.scenes,
    mainComponent: Scenes,
    adminOnly: false,
    permissionKey: "Scenes",
  },
  {
    path: ROUTES_PATHS.Scene.scenePage,
    mainComponent: ScenePage,
    metadata: {
      editForm: UpdateSceneFormRules,
      createForm: UploadSceneFormRules,
    },
    adminOnly: false,
    permissionKey: "Scenes",
  },
  {
    path: ROUTES_PATHS.Scene.sceneItemPage,
    mainComponent: SceneItemPage,
    metadata: {
      editForm: UpdateSceneItemFormRules,
      createForm: UploadSceneItemFormRules,
    },
    adminOnly: false,
    permissionKey: "Scenes",
  },
  {
    path: ROUTES_PATHS.Dashboard.boxes,
    mainComponent: Boxes,
    adminOnly: false,
    permissionKey: "Boxes",
  },
  {
    path: ROUTES_PATHS.Box.boxPage,
    mainComponent: BoxPage,
    metadata: { editForm: UpdateBoxFormRules, createForm: UploadBoxFormRules },
    adminOnly: false,
    permissionKey: "Boxes",
  },
  {
    path: ROUTES_PATHS.Box.boxPrizePage,
    mainComponent: PrizePage,
    metadata: {
      editForm: UpdateBoxPrizeFormRules,
      createForm: UploadBoxPrizeFormRules,
    },
    adminOnly: false,
    permissionKey: "Boxes",
  },
  {
    path: ROUTES_PATHS.Dashboard.levels,
    mainComponent: Levels,
    adminOnly: false,
    permissionKey: "Levels",
  },
  {
    path: ROUTES_PATHS.Level.levelPage,
    mainComponent: LevelPage,
    metadata: {
      editForm: UpdateLevelFormRules,
      createForm: UploadLevelFormRules,
    },
    adminOnly: false,
    permissionKey: "Levels",
  },
  {
    path: ROUTES_PATHS.Dashboard.dailyRewards,
    mainComponent: DailyRewards,
    adminOnly: false,
    permissionKey: "Daily Rewards",
  },
  {
    path: ROUTES_PATHS.DailyReward.dailyRewardPage,
    mainComponent: DailyRewardPage,
    metadata: {
      editForm: UpdateDailyRewardFormRules,
      createForm: UploadDailyRewardFormRules,
    },
    adminOnly: false,
    permissionKey: "Daily Rewards",
  },
  {
    path: ROUTES_PATHS.Dashboard.gameConfig,
    mainComponent: GameConfig,
    metadata: { editForm: UpdateGameConfigFormRules },
    adminOnly: false,
    permissionKey: "Settings",
  },
  {
    path: ROUTES_PATHS.Dashboard.fota,
    mainComponent: Fota,
    metadata: { editForm: UploadFirmwareFormRules },
    adminOnly: false,
    permissionKey: "Fota",
  },
];
