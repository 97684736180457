import { Divider, Typography } from "@mui/material";

//Interfaces
import { FlexColumn } from "components/common/containers";
import { ISubscription } from "../../../../interfaces/models";

//Components
import { Form } from "../../../../components/form";
import { EventsPopup } from "../eventsPopup";
import { AdditionalData } from "../additionalData";
import { SubscriptionActions } from "./components";
import { Root, OpenPopupButton } from "./SubscriptionForm.style";

//Utils
import { getDetails } from "utils";

//Constants
import { SUBSCRIPTION_FORM } from "./constants";

//Hooks
import { useState } from "react";
import { useAsync } from "hooks/useAsync";

//Services
import { rechargeApi } from "services/api";

export const SubscriptionPageForm = ({
  subscription,
  refetch,
}: {
  subscription: ISubscription;
  refetch: () => void;
}) => {
  const [popupOpen, setPopupOpen] = useState(false);

  const details = getDetails(SUBSCRIPTION_FORM, subscription);

  const additionalInfo = subscription.additional_info;

  const subStatus = additionalInfo ? additionalInfo["status"] : undefined;

  const subId = subscription.subscription_id;

  const { value: customerTimeline } = useAsync(
    () =>
      rechargeApi.getCustomerTimeline(
        additionalInfo ? additionalInfo["customer_id"] : ""
      ),
    [additionalInfo],
    !!additionalInfo && !!additionalInfo["customer_id"]
  );

  return (
    <Root>
      <FlexColumn>
        <Form details={details} row={subscription} />
      </FlexColumn>

      <>
        <Divider orientation="vertical" sx={{ margin: "0 2rem 0" }} flexItem />

        <FlexColumn>
          {
            <SubscriptionActions
              subscription={subscription}
              refetch={refetch}
              status={subStatus}
              id={subId}
              additionalInfo={additionalInfo}
            />
          }
          {!!customerTimeline?.length && (
            <>
              <OpenPopupButton onClick={() => setPopupOpen(true)}>
                Show Customer Timeline
              </OpenPopupButton>
              <EventsPopup
                events={customerTimeline}
                open={popupOpen}
                onClose={() => setPopupOpen(false)}
              />
            </>
          )}
          {additionalInfo && (
            <>
              <Typography sx={{ marginBottom: "2rem" }} variant="h4">
                Additional info
              </Typography>
              <AdditionalData data={subscription.additional_info} />
            </>
          )}
        </FlexColumn>
      </>
    </Root>
  );
};
